import './App.css'

function App() {
    return (
        <div className="controlling">
            <div id="home">
                <div className="middle">
                    <div className="text-control">
                        <h1>
                            This is My Game Stat!
                        </h1>
                        <hr />
                        <span>
                            This website is making for saving my game status UwU
                        </span>
                    </div>
                </div>
            </div>
            <div id="status">
                {/* <div className="card">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="imgCon">
                                <div className="img" id="img" style={{ background: "url('https://media.discordapp.net/attachments/677513928711995422/888421106954956830/dt1.png')", "background-position": "center", "background-size": "cover"}}>
                                    .
                                </div>
                            </div>
                            <div className="textCon">
                                <div className="card-body">
                                    <h5 className="card-title" id="gName">
                                        Deltarune Chapter 1
                                    </h5>
                                    <p className="card-text">
                                        <b>Play time : </b><span id="pTime">2 Hr. 22 Min.</span>
                                        <br />
                                        <b>Achievement : </b><span id="aCount">0 / 0</span>
                                        <br />
                                        <b>Story Status : </b><span id="sStat">Done</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default App;